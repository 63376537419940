import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {AuthenticationService} from './shared/services/authentication.service';
import {LayoutComponent} from './shared/layout/layout.component';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    LayoutComponent
  ],
})
export class AppComponent implements OnInit {

  constructor(private authService: AuthenticationService,   @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    // Set a timeout to auto-logout after 10 minutes (600,000 ms)
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.authService.logout();
      }, 600000);
    }
  }
}
