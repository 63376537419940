import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import {LocalStorageService} from './local-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storageService: LocalStorageService, @Inject(PLATFORM_ID) private platformId: Object) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storageService.getItem('token');
    const session = this.storageService.getItem('notionUserId');

    let headers = req.headers;
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    if (session) {
      headers = headers.append('Session', session);
    }

    const authReq = req.clone({ headers });
    return next.handle(authReq);
  }
}
