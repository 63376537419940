import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {SharedModule} from '../shared.module';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
    SharedModule,
  ],
})
export class LayoutComponent {

}
